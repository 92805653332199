import React, { useState } from 'react';
import axios from 'axios';
import { request } from '../../api/authenticationService';

const UploadExcel = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await request(
          'POST',
          '/superadmin/uploadxls',
          formData,
          'multipart/form-data'
        );
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('Please select a file to upload.');
    }
  };

  return (
    <>
      <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
        Upload Students List
      </h1>
      <div className="space-x-2 flex justify-center items-center">
        <input
          className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          type="file"
          onChange={handleFileChange}
        />
        <button
          className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
    </>
  );
};

export default UploadExcel;
