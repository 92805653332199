import react, { useState } from 'react';
import {
  request,
  setAuthHeader,
} from '../../api/authenticationService';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginPage = ({ loading, error, setData, ...props }) => {
  const [values, setValues] = useState({
    userName: '',
    password: '',
    twoFaCodeFromUser: '',
  });

  const [loginFailure, setLoginFailure] = useState('');
  const [otp, setOtp] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false); // State to track loading

  const location = useLocation();
  const navigate = useNavigate();
  const onLoginOTP = (e) => {
    e.preventDefault();
    const { userName, twoFaCodeFromUser } = values;
    if (userName.trim() === '' || twoFaCodeFromUser.trim() === '')
      return;

    const doLoginOTP = async () => {
      setIsLoginLoading(true); // Disable the button
      try {
        const response = await request('POST', '/auth/verify-otp', {
          userName: userName,
          twoFaCodeFromUser: twoFaCodeFromUser,
        });
        setAuthHeader(response.data.token);
        setLoginFailure('');

        const response2 = await request('GET', '/auth/userinfo', {});
        setData(response2.data);

        response2?.data?.roles?.filter(
          (value) => value.roleCode === 'SUPER_ADMIN'
        ).length > 0
          ? navigate('/schoolsuperadmin')
          : navigate('/school');

        setOtp(false);
      } catch (err) {
        console.log(err);
        localStorage.clear();
        setAuthHeader(null);
        console.log('ERROR::..');
        setTimeout(() => {
          setLoginFailure('');
        }, 5000);
        if (err?.response) {
          setOtp(false);
          setLoginFailure('Something Wrong!Please Try Again');
        }
      } finally {
        setIsLoginLoading(false); // Enable the button
      }
    };
    doLoginOTP();
  };

  const onLogin = (e) => {
    e.preventDefault();
    const { userName, password } = values;
    if (userName.trim() === '' || password.trim() === '') return;

    const doLogin = async () => {
      setIsLoginLoading(true); // Disable the button
      try {
        const response = await request('POST', '/auth/login', {
          userName: userName,
          password: password,
        });
        if (response.status === 204) {
          console.log('204 status');
          setLoginFailure('OTP required!');
          setOtp(true);
          return;
        }
        setAuthHeader(response.data.token);
        setLoginFailure('');

        const response2 = await request('GET', '/auth/userinfo', {});
        setData(response2.data);

        response2?.data?.roles?.filter(
          (value) => value.roleCode === 'SUPER_ADMIN'
        ).length > 0
          ? navigate('/schoolsuperadmin')
          : response2?.data?.roles?.filter(
              (value) => value.roleCode === 'ADMIN'
            ).length > 0
          ? navigate('/schooladmin')
          : navigate('/school');
      } catch (err) {
        console.log(err);
        localStorage.clear();
        setAuthHeader(null);
        console.log('ERROR::..');
        setTimeout(() => {
          setLoginFailure('');
        }, 5000);
        if (err?.response) {
          switch (err.response.status) {
            case 401:
              console.log('401 status');
              setLoginFailure(
                'Authentication Failed.Bad Credentials'
              );
              break;

            default:
              setLoginFailure('Something Wrong!Please Try Again');
          }
        } else {
          setLoginFailure('Something Wrong!Please Try Again');
        }
      } finally {
        setIsLoginLoading(false); // Enable the button
      }
    };
    doLogin();
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { userName, password } = values;
    if (userName.trim() === '' || password.trim() === '') return;
    otp ? onLoginOTP(evt) : onLogin(evt);
  };

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="flex border bg-bg flex-col items-center justify-center px-6 py-8 md:h-screen home-page-center-element ">
      <h1 className="flex items-center text-center mb-6 text-2xl font-semibold text-gray-900 ">
        WELCOME TO ANNOUR MANAGEMENT SYSTEM
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="userName"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-white px-3 py-2.5 font-sans text-sm font-semibold text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-semibold leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              User Name
            </label>
          </div>
        </div>
        {otp ? (
          <div className="flex w-72 flex-col gap-6 my-4">
            <div className="relative h-10 w-full min-w-[200px]">
              <input
                name="twoFaCodeFromUser"
                onChange={handleChange}
                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-white px-3 py-2.5 font-sans text-sm font-semibold text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700  focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" "
                autoComplete="off"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-semibold leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                One Time Pad
              </label>
            </div>
          </div>
        ) : (
          <div className="flex w-72 flex-col gap-6 my-4">
            <div className="relative h-10 w-full min-w-[200px]">
              <input
                name="password"
                onChange={handleChange}
                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-white px-3 py-2.5 font-sans text-sm font-semibold text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700  focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" "
                autoComplete="off"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-semibold leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Password
              </label>
            </div>
          </div>
        )}
        <div className="flex w-72 flex-col gap-6">
          <button
            type="submit"
            onClick={handleSubmit}
            className={`rounded text-white px-6 py-2 font-semibold ${
              isLoginLoading
                ? 'bg-gray-500 cursor-not-allowed'
                : 'bg-green-700 hover:bg-green-900'
            }`}
            disabled={isLoginLoading}
          >
            {isLoginLoading ? (
              <div className="flex justify-center items-center">
                {/* Flex container for spinner and text */}
                <div className="spinner"></div> {/* CSS spinner */}
                <span className="ml-2">Logging in...</span>
              </div>
            ) : (
              'Login'
            )}
          </button>
        </div>
      </form>

      {loginFailure.length > 0 && (
        <div className="rounded bg-red-600 text-white my-5 py-4 w-96 text-center">
          {loginFailure}
        </div>
      )}
    </div>
  );
};

export default LoginPage;
