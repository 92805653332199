import React from 'react';
//import { useMediaQuery } from 'react-responsive';

const ContactInfoPage = () => {
  /*const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  });*/

  return (
    <div className="mx-auto text-center lg:text-lg md:text-md sm:text-md px-6 bg-bg minus80px">
      <div className=" lg:flex-row lg:flex items-center">
        <div className="ml-auto text-center pt-6 lg:text-lg md:text-md sm:text-md lg:p-10 sm:p-2 bg-bg">
          <div className="max-w-xl mx-auto  p-6 bg-white rounded-md shadow-xl">
            <div className="mb-4 flex flex-col">
              <div className="relative ">
                <h2 className="text-center text-2xl font-bold mb-4 justify-between">
                  Kontakt
                </h2>
                <div className="flex space-x-5 justify-between">
                  <div className="flex-shrink-0 font-semibold">
                    Telefon
                  </div>

                  <div className="flex-shrink-0 text-blue-600 underline">
                    <a
                      className=" font-medium"
                      href="tel:0049 152 594 315 15"
                    >
                      +49 152 594 315 15
                    </a>
                  </div>
                </div>

                <div className="flex items-center space-x-5  justify-between">
                  <div className="flex-shrink-0 font-semibold">
                    Email
                  </div>

                  <div className="flex-shrink-0 text-blue-600 underline">
                    <a
                      className=" font-medium"
                      href="mailto:verwaltung@annour-bs.de"
                    >
                      verwaltung@annour-bs.de
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-xl mx-auto mt-8 p-6 bg-white rounded-md shadow-xl">
            <div className="mb-4 flex flex-col">
              <div className="relative ">
                <h2 className="text-center text-2xl font-bold mb-4 justify-between">
                  Öffnungszeiten
                </h2>
                <div className="flex space-x-5 justify-between">
                  <div className="flex-shrink-0 font-semibold">
                    Samstags
                  </div>

                  <div className="flex-shrink-0 font-medium">
                    14:15 - 16:00 Uhr
                  </div>
                </div>

                <div className="flex items-center space-x-5  justify-between">
                  <div className="flex-shrink-0 font-semibold">
                    Montags
                  </div>

                  <div className="flex-shrink-0 font-medium">
                    16:30 - 18:15 Uhr
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-xl mr-auto lg:mt-0 mt-8 pt-8 p-6 bg-white  rounded-md shadow-xl">
          <div className="mb-4 flex flex-col">
            <h2 className="text-center text-2xl font-bold mb-4 justify-between">
              Unterrichtsaktivitätsort
            </h2>
            <div className="">
              Varrentrappstraße 21{<br />}38114 Braunschweig{<br />}
              Deutschland
            </div>
            <iframe
              className="mx-auto my-2"
              title="location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2441.0344272681277!2d10.505879477069103!3d52.27907695403838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aff68a22cf7deb%3A0x671a12dc58b903ff!2sVarrentrappstra%C3%9Fe%2021%2C%2038114%20Braunschweig!5e0!3m2!1sen!2sde!4v1681182134005!5m2!1sen!2sde"
              width="200"
              height="150"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoPage;
