import React from 'react';
import { useNavigate } from 'react-router-dom';
import annourLogo from '../../imgs/annour-logo.png';
import homeBg from '../../imgs/schule4.jpeg';
import AnnourNavbar from '../AnnourNavbar';
import StudentRegForm from './StudentRegForm';

const StudentRegistrationPage = () => {
  const navigate = useNavigate();

  return <StudentRegForm />;
};

export default StudentRegistrationPage;
