import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { request } from '../../api/authenticationService';
import SchoolServiceAdmin from '../../services/SchoolServiceAdmin';
import { levelColors, levelTranslations } from '../ConstFuncs';

const ArchivedStudentList = ({ allArchivedStudents, loading }) => {
  const [isStudentsTableVisible, setIsStudentsTableVisible] =
    useState(false);

  const toggleStudentsTableVisibility = () => {
    setIsStudentsTableVisible(!isStudentsTableVisible);
  };

  return (
    <>
      <div className="inline-flex items-center justify-center w-full ">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleStudentsTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isStudentsTableVisible
              ? 'Hide All Archived Students'
              : 'Show All Archived Students'}
          </button>
        </div>
      </div>

      {!loading && isStudentsTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden ">
            <thead className="bg-gray-800 text-white text-sm md:text-base lg:text-lg">
              <tr>
                <th className="py-2">First Name Arabic</th>
                <th className="py-2">Last Name Arabic</th>
                <th className="py-2">Parents Email</th>
                <th className="py-2">Class Days Of Week</th>
                <th className="py-2">Student Level</th>
              </tr>
            </thead>
            <tbody>
              {allArchivedStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2">{myStudent.lastNameArabic}</td>
                  <td className="py-2">{myStudent.email}</td>
                  <td className="py-2">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>

                  <td
                    className={`py-2 font-semibold text-gray-300 ${
                      myStudent.level != null
                        ? levelColors[myStudent.level]
                        : 'bg-slate-600'
                    }`}
                  >
                    {myStudent.level != null
                      ? levelTranslations[myStudent.level]
                      : 'لم يتم الاختيار بعد'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2  mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
    </>
  );
};

export default ArchivedStudentList;
