import { useState } from 'react';

const Popup = ({ isOpen, togglePopup, msg }) => {
  return !isOpen ? (
    <></>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded shadow-lg transform transition-all duration-300">
          <h2 className="text-2xl mb-4">Popup Content</h2>
          <p>{msg}</p>
          <button
            onClick={togglePopup}
            className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
