import React, { useRef, useState } from 'react';
import pricing from '../../files/gebuehren_end_version_2022.pdf';
import emailjs from '@emailjs/browser';
import Popup from '../Popup';

const ProgrammingCoursesPage = () => {
  return (
    <div className="mx-auto bg-bg p-3 minus80px">
      <div className="max-w-6xl mx-auto mt-4 p-6 bg-white rounded-md shadow-xl">
        <h1 className="lg:text-3xl font-extrabold text-xl my-1">
          NEUE KURSE
        </h1>
        <h2 className="lg:text-xl font-extrabold text-l my-1">
          Wir haben dieses Jahr 3 Kurse in der FiBS - Ferien in
          Braunschweig 2024 angeboten:
        </h2>
        <div className="p-5 block text-blue-900 text-lg md:text-2xl font-bold">
          {/*ZURZEIT FINDET KEINE NEUEN KURSE STATT.*/}
          <a
            className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
            href={
              'https://www.unser-ferienprogramm.de/braunschweig/veranstaltung.php?v=50387&id=204130'
            }
          >
            1. Kurs zu Arduino und Mikrocontroller am 08.07.2024
          </a>
          <a
            className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
            href={
              'https://www.unser-ferienprogramm.de/braunschweig/veranstaltung.php?v=50387&id=204117'
            }
          >
            2. Kurs mit der Programmiersprache Scratch von 09.07.2024
            bis 10.07.2024
          </a>
          <a
            className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
            href={
              'https://www.unser-ferienprogramm.de/braunschweig/veranstaltung.php?v=50387&id=204127'
            }
          >
            3. Kurs mit der Programmiersprache Scratch von 11.07.2024
            bis 12.07.2024
          </a>
        </div>

        <div className="py-5">
          <h1 className="lg:text-3xl font-extrabold text-xl my-1">
            ALTE KURSE
          </h1>
          <div className="p-5">
            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={
                'https://meet-and-code.org/de/de/event-show/10227'
              }
            >
              Python Grundlagen
            </a>
            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={
                'https://meet-and-code.org/de/de/event-show/10570'
              }
            >
              Einführung in Scratch für Kids
            </a>
            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={
                'https://meet-and-code.org/de/de/event-show/10373'
              }
            >
              Roboter Programmieren mit Python
            </a>

            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={'https://meet-and-code.org/de/de/event-show/9266'}
            >
              Python für Kids
            </a>
            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={'https://meet-and-code.org/de/de/event-show/7765'}
            >
              PC Grundlage und Libre Office Training
            </a>
            <a
              className="underline py-4 block text-blue-600 text-base md:text-xl font-bold"
              href={'https://meet-and-code.org/de/de/event-show/7744'}
            >
              Training Java-Programmierung
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammingCoursesPage;
