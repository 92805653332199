import React from 'react';
import { useNavigate } from 'react-router-dom';
import annourLogo from '../../imgs/annour-logo.png';
import homeBg from '../../imgs/schule4_.jpeg';
import homeBg3 from '../../imgs/schule1_.jpeg';
import homeBg2 from '../../imgs/schule5_.jpeg';
import homeBg4 from '../../imgs/schule2.jpeg';
import homeBg5 from '../../imgs/schule3.jpeg';
import homeBg6 from '../../imgs/schule6.jpeg';

import AnnourNavbar from '../AnnourNavbar';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PurposeOfAssociation from './PurposeOfAssociation';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className=" bg-bg ">
      <div className=" items-center justify-center  text-white ">
        <div className="md:absolute md:inset-y-0 md:left-0 z-10 md:mt-40 md:mb-60 md:mx-20 text-center  sm:text-center md:text-left md:rounded-2xl md:p-10 p-3 sm:p-12 bg-main shadow-2xl md:shadow-gray-500 opacity-90 md:w-1/3 sm:w-1/2">
          <h1 className="flex md:h-full items-center justify-center text-4xl md:text-6xl leading-normal">
            Willkommen auf Arabische Schule in Braunschweig Annour
            e.V.!
          </h1>
        </div>
        <Carousel
          className="shadow-lg "
          infiniteLoop
          autoPlay={true}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          stopOnHover={false}
        >
          <div className="minus160px">
            <img src={homeBg} alt="img1" />
            {/**<p className="legend">Legend 1</p>**/}
          </div>
          <div className="minus160px">
            <img src={homeBg2} alt="img2" />
          </div>
          <div className="minus160px">
            <img src={homeBg3} alt="img2" />
          </div>
          <div className="minus160px">
            <img src={homeBg4} alt="img2" />
          </div>
          <div className="minus160px">
            <img src={homeBg5} alt="img2" />
          </div>
          <div className="minus160px">
            <img src={homeBg6} alt="img2" />
          </div>
        </Carousel>
      </div>
      <PurposeOfAssociation />
    </div>
  );
};

export default HomePage;
