import React from 'react';
import annourSatzungPDF from '../../files/Anoor_Verein_Satzung_11092015.pdf';

const AboutMembers = () => {
  const members = [
    {
      function: '1.Vorsitzender',
      title: 'Herr Dr.',
      firstName: 'Abdulghani',
      lastName: 'Albadawi',
    },
    {
      function: 'Stellvertretenden Vorsitzenden',
      title: 'Herr Dr.',
      firstName: 'Yaser',
      lastName: 'Daaboul',
    },
    {
      function: 'Kassenwart',
      title: 'Herr Dr.',
      firstName: 'M.Yasser',
      lastName: 'Al Nahlaoui',
    },
    {
      function: 'Schriftführer',
      title: 'Herr Dr.-Med.',
      firstName: 'Ehab',
      lastName: 'Abu-El-Auf',
    },
    {
      function: 'Beisitzer',
      title: 'Herr Dr.',
      firstName: 'Anas',
      lastName: 'Alfarra',
    },
  ];

  return (
    <div className="md:w-1/2 sm:w-full mt-32 lg:mt-2 py-8 text-center">
      <h1 className="text-3xl mb-4">Vorstand Annour e.V</h1>
      <h1 className="text-3xl mb-4">(seit 27.01.2019)</h1>

      <table className="w-full lg:text-lg text-sm md:text-base border-collapse border border-gray-300">
        <thead>
          <tr className="bg-white">
            <th className="border text-black border-gray-300 p-2">
              <h4 className="font-bold">Funktion</h4>
            </th>
            <th className="border text-black border-gray-300 p-2">
              <h4 className="font-bold">Titel</h4>
            </th>
            <th className="border text-black border-gray-300 p-2">
              <h4 className="font-bold">Vorname</h4>
            </th>
            <th className="border text-black border-gray-300 p-2">
              <h4 className="font-bold">Name</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr
              key={index}
              className={
                (index + 1) % 2 === 0 ? 'bg-white' : 'bg-slate-50'
              }
            >
              <td className="border text-black border-gray-300 p-2">
                {member.function}
              </td>
              <td className="border text-black border-gray-300 p-2">
                {member.title}
              </td>
              <td className="border text-black border-gray-300 p-2">
                {member.firstName}
              </td>
              <td className="border text-black border-gray-300 p-2">
                {member.lastName}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h1 className="text-3xl my-4">
        <a
          className="underline text-blue-600"
          href={annourSatzungPDF}
          download={annourSatzungPDF}
        >
          Satzung von Annourverein
        </a>
      </h1>
      <div className=" mr-auto mt-8 p-6 text-gray-500 bg-white rounded-md shadow-xl h-fit">
        <div className="mb-4">
          <div className="relative ">
            <h2 className="text-center text-2xl font-bold mb-4 justify-between">
              Vereinssitz
            </h2>
            <div className="flex-shrink-0 ">
              Arabische Schule in Braunschweig Annour e.V.
              {<br />}Petzvalstr, 50
              {<br />}38104 Braunschweig
              {<br />}Deutschland
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMembers;
