import React, { useRef, useState } from 'react';
import pricing from '../../files/gebuehren_end_version_2022.pdf';
import emailjs from '@emailjs/browser';
import Popup from '../Popup';

const GermanLangSupportCourse = () => {
  return (
    <div className="mx-auto bg-bg p-3 home-page-center-element ">
      <div className="max-w-6xl mx-auto mt-4 p-6 bg-white rounded-md shadow-xl">
        <h1 className="text-xl md:text-2xl my-1">
          Seit 2017 hat Annour e.V. im Kooperation mit Jugendamt
          Braunschweig Sprachförderungskurse.
        </h1>
        <a
          className="underline text-blue-600  text-xl md:text-2xl font-extrabold"
          href={'https://www.braunschweig.de/vv/oe/V/51/index.php'}
        >
          Jugendamt Braunschweig
        </a>
        <h2 className="text-xl md:text-2xl mt-10">
          Wir bieten die Schüler 1. bis 9. Klasse zwei Termine pro
          Woche für Deutsch-Nachhilfe an:
        </h2>
        <h2 className="text-xl md:text-2xl mt-2 font-semibold">
          - Mittwochs: 17:00 - 18:30 Uhr
        </h2>
        <h2 className="text-xl md:text-2xl mt-2 font-semibold">
          - Samstags: 15:45 - 17:15 Uhr
        </h2>
        <h2 className="text-xl md:text-2xl mt-10 font-semibold">
          Anmeldung per WhatsApp:{' '}
          <div className="flex-shrink-0 text-blue-600 underline">
            <a
              className=" font-medium"
              href="tel:0049 152 594 315 15"
            >
              +49 152 594 315 15
            </a>
          </div>
        </h2>
      </div>
    </div>
  );
};

export default GermanLangSupportCourse;
