import { useEffect, useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../styles/AnnourNavbar.css';
import annourLogo from '../imgs/annour-logo.png';
import sun from '../imgs/sun00.png';

import { Link, useNavigate } from 'react-router-dom';

function AnnourNavbar({ data, setData }) {
  const navRef = useRef();
  const navigate = useNavigate();

  const showNavbar = (show = true) => {
    show
      ? navRef.current.classList.add('responsive_nav')
      : navRef.current.classList.remove('responsive_nav');
  };

  let handleClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    showNavbar(false);
  };
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const activeStyle = {
    color: 'yellow',
    fontWeight: 'bold',
  };

  useEffect(() => {
    const pathname = window.location.pathname.slice(1);
    setActiveMenuItem(pathname.length === 0 ? 'home' : pathname);
  }, []);

  const logOut = () => {
    localStorage.clear();
    setData(null);
    navigate('/');
  };

  return (
    <header className="px-1 sm:px-4">
      <div>
        <img
          className="rounded-ss-3xl h-10 px-1 sm:h-16 sm:px-2"
          src={annourLogo}
          alt="img-logo"
        />
        {!data && (
            <p className="text-white text-xs md:text-base font-bold pr-1 md:pr-14">
              ANNOUR SCHULE
            </p>
          ) && (
            <img
              alt="sun img"
              src={sun}
              className="rotate-sun h-12 w-12"
            />
          )}
        {data && (
          <h2 className="text-lg md:text-xl">
            {'أهلا ً:  ' + data.firstName}
          </h2>
        )}
        {!data && (
          <nav ref={navRef}>
            <Link
              style={activeMenuItem === 'home' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'home')}
              className="link-underline"
              to="/"
            >
              HOME
            </Link>
            <Link
              style={
                activeMenuItem === 'studentreg' ? activeStyle : {}
              }
              onClick={handleClick.bind(this, 'studentreg')}
              className="link-underline"
              to="/studentreg"
            >
              KINDER-ANMELDUNG
            </Link>
            <Link
              style={activeMenuItem === 'glsc' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'glsc')}
              className="link-underline"
              to="/glsc"
            >
              SPRACHFÖRDERUNG DEUTSCH
            </Link>
            <Link
              style={
                activeMenuItem === 'programmingcourses'
                  ? activeStyle
                  : {}
              }
              onClick={handleClick.bind(this, 'programmingcourses')}
              className="link-underline"
              to="/programmingcourses"
            >
              PROGRAMMIERKURSE
            </Link>
            <Link
              style={activeMenuItem === 'contact' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'contact')}
              className="link-underline"
              to="/contact"
            >
              KONTAKT-DATEN
            </Link>
            <Link
              style={activeMenuItem === 'about' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'about')}
              className="link-underline"
              to="/about"
            >
              ÜBER UNS
            </Link>

            <button
              onClick={() => {
                handleClick('login');
                navigate('/login');
              }}
              className="rounded bg-green-800 text-white px-6 py-2 font-semibold hover:bg-green-600 login-btn-sm"
            >
              LOGIN
            </button>
            <button
              className="nav-btn nav-close-btn"
              onClick={() => showNavbar(false)}
            >
              <FaTimes />
            </button>
          </nav>
        )}
      </div>
      {!data && (
        <div>
          <button
            onClick={() => {
              handleClick('login');
              navigate('/login');
            }}
            className="rounded bg-green-800 text-white px-6 py-2 font-semibold hover:bg-green-600 login-btn"
          >
            LOGIN
          </button>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      )}

      {data && (
        <button
          onClick={() => logOut()}
          className="rounded bg-red-800 text-white px-6 py-2 font-semibold hover:bg-red-900"
        >
          تسجيل خروج
        </button>
      )}
    </header>
  );
}

export default AnnourNavbar;
