import React, { useRef, useState } from 'react';
import pricing from '../../files/gebuehren_end_version_2022.pdf';
import emailjs from '@emailjs/browser';
import Popup from '../Popup';

const StudentRegForm = () => {
  const [formData, setFormData] = useState({
    vorkenntnisse: false,
    nachname: '',
    vorname: '',
    geburtsdatum: '',
    plz: '',
    telefon: '',
    email: '',
    text: '',
  });

  const form = useRef();

  const [msg, setMsg] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_00kwshd',
        'template_ka9thgs',
        form.current,
        'DiyRY4XvPMpzgMZI2'
      )
      .then(
        (result) => {
          togglePopup();
          setMsg('Anfrage wurde erfolgreich verschickt');
          setFormData({
            vorkenntnisse: false,
            nachname: '',
            vorname: '',
            geburtsdatum: '',
            plz: '',
            telefon: '',
            email: '',
            text: '',
          });
        },
        (error) => {
          togglePopup();
          setMsg('Fehlgeschlagen! :/');
        }
      );
    e.target.reset();
  };

  const [checkboxActiv, setCheckboxActiv] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const checkboxClicked = (e) => {
    setCheckboxActiv(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    sendEmail(e);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    'Bitte auswählen',
    'Durch eine Suchmaschine',
    'Durch soziale Medien',
    'Durch Empfehlungen von Freunden',
    'Lieber nicht beantworten',
  ];

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const togglePopup = () => {
    if (popupIsOpen) {
      setMsg('');
    }
    setPopupIsOpen(!popupIsOpen);
  };

  return (
    <div className="mx-auto text-center bg-bg p-6 minus80px">
      <Popup
        togglePopup={togglePopup}
        isOpen={popupIsOpen}
        msg={msg}
      />
      <h2 className="text-xl my-1">
        Die monatlichen Unterrichtsgebühren der arabischen Unterrichte
        können Sie hier lesen
      </h2>
      <a
        className="underline text-blue-600 text-3xl font-extrabold"
        href={pricing}
        download={pricing}
      >
        Gebühren
      </a>
      <h2 className="text-xl pt-5">
        Sie können Ihr Kind bei uns mittels untenstehendem Formular
        auf die Warteliste anmelden lassen
      </h2>

      <form
        ref={form}
        className="max-w-xl mx-auto mt-8 p-6 bg-white rounded-md shadow-xl"
        onSubmit={handleSubmit}
      >
        <div className="mb-4 flex items-center">
          <label
            htmlFor="vorkenntnisse"
            className="flex items-center text-sm font-medium text-gray-700"
          >
            Sind Vorkenntnisse im Lesen der arabischen Sprache
            vorhanden:
          </label>

          <label className="ml-4 relative inline-flex items-center cursor-pointer">
            <input
              name="vorkenntnisse"
              type="checkbox"
              onClick={checkboxClicked}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        {/*checkboxActiv && <p>lesen? hören? schreiben? sprechen?</p>*/}

        <div className="mb-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="nachname"
              onChange={handleChange}
              value={formData.nachname}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
              required
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              *Nachname
            </label>
          </div>
        </div>

        <div className="mb-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="vorname"
              onChange={handleChange}
              value={formData.vorname}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
              required
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              *Vorname
            </label>
          </div>
        </div>

        <div className="mb-4 relative h-10 w-full min-w-[200px]">
          <input
            type="date"
            name="geburtsdatum"
            value={formData.geburtsdatum}
            onChange={handleChange}
            className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            placeholder=" "
          />
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Geburtsdatum
          </label>
        </div>

        <div className="mb-4 relative h-10 w-full min-w-[200px]">
          <input
            type="number"
            name="plz"
            value={formData.plz}
            onChange={handleChange}
            className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            placeholder=" "
          />
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Postleitzahl
          </label>
        </div>

        <div className="mb-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="telefon"
              onChange={handleChange}
              value={formData.telefon}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
              required
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              *Telefon
            </label>
          </div>
        </div>

        <div className="mb-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="email"
              onChange={handleChange}
              value={formData.email}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
              required
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              *E-mail
            </label>
          </div>
        </div>

        <div className="mb-4">
          <div className="relative h-auto w-full min-w-[200px]">
            <textarea
              name="text"
              onChange={handleChange}
              value={formData.text}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
              rows="2"
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-cyan-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-cyan-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Beschreibung oder Hinweise
            </label>
          </div>
        </div>
        {/*
        <div className="mb-4 flex-col items-center mx-auto space-y-4">
          <label className="items-center text-sm font-medium text-gray-700">
            Woher kennen Sie uns?
          </label>
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className="w-full appearance-none bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
            */}
        <div className="flex justify-between">
          <button
            onClick={() => {
              setFormData({
                vorkenntnisse: false,
                nachname: '',
                vorname: '',
                geburtsdatum: '',
                plz: '',
                telefon: '',
                email: '',
                text: '',
              });
            }}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
          >
            Formular löschen
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Absenden
          </button>
        </div>
      </form>
    </div>
  );
};

export default StudentRegForm;
