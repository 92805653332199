import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from 'chart.js';

const AttendanceData = ({ data }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const attendanceCounts = {};

  data?.forEach((student) => {
    student.forEach((record) => {
      const name = `${record.studentEntity.firstName} ${record.studentEntity.lastName}`;
      if (record.present) {
        attendanceCounts[name] = (attendanceCounts[name] || 0) + 1;
      }
    });
  });

  const calculateColors = () => {
    const values = Object.values(attendanceCounts);
    const maxAttendance = Math.max(...values);
    const minAttendance = Math.min(...values);

    return Object.values(attendanceCounts).map((count) => {
      if (count === maxAttendance) {
        return 'rgba(75, 192, 192, 0.7)'; // Green for highest attendance
      } else if (count === minAttendance) {
        return 'rgba(255, 99, 132, 0.7)'; // Red for lowest attendance
      }
      return 'rgba(54, 162, 235, 0.2)'; // Default color for others
    });
  };

  const options1 = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Ensures the scale increments by 1
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Allows custom dimensions without maintaining the aspect ratio
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Student Attendance',
      },
    },
  };

  const chartData = {
    labels: Object.keys(attendanceCounts),
    datasets: [
      {
        label: 'Number of Days Present',
        data: Object.values(attendanceCounts),
        backgroundColor: calculateColors(),
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        barThickness: 40, // Adjust this value to control the bar thickness
      },
    ],
  };

  const chartStyle = {
    width: '50vw', // 50% of the viewport width
  };

  return (
    <div className="flex justify-center items-center w-full">
      <Bar style={chartStyle} options={options1} data={chartData} />{' '}
    </div>
  );
};

export default AttendanceData;
