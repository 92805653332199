import react, { useEffect, useState } from 'react';
//import './loginpage.css';
import { request, setAuthHeader } from '../api/authenticationService';
import { useNavigate } from 'react-router-dom';
import SchoolServiceAdmin from '../services/SchoolServiceAdmin';

const CreateStudent = ({ data, setData }) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    firstNameArabic: '',
    lastNameArabic: '',
    email: '',
    classDaysOfWeek: [],
    teacherId: -1,
  });

  const [signupFailure, setSignupFailure] = useState('');
  const navigate = useNavigate();

  const onSignup = (e) => {
    e.preventDefault();
    const {
      email,
      firstName,
      lastName,
      classDaysOfWeek,
      teacherId,
      firstNameArabic,
      lastNameArabic,
    } = values;
    console.log(values);
    request('POST', `/superadmin/addstudenttoteacher/${teacherId}`, {
      classDaysOfWeek: classDaysOfWeek,
      email: email,
      firstName: firstName,
      lastName: lastName,
      firstNameArabic: firstNameArabic,
      lastNameArabic: lastNameArabic,
    })
      .then((response) => {
        setSignupFailure('');
        navigate('/schoolsuperadmin');
      })
      .catch((err) => {
        console.log('ERROR::..');
        setTimeout(() => {
          setSignupFailure('');
        }, 5000);

        if (err && err.response) {
          switch (err.response.status) {
            case 401:
              console.log('401 status');
              setSignupFailure(
                'Authentication Failed.Bad Credentials'
              );
              break;
            default:
              setSignupFailure('Something Wrong!Please Try Again');
          }
        } else {
          setSignupFailure('Something Wrong!Please Try Again');
        }
      });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onSignup(evt);
  };

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedClassDays = [...values.classDaysOfWeek];

    // Update the classDaysOfWeek list based on checkbox state
    if (checked) {
      updatedClassDays.push(value); // Add the day to the list if checked
    } else {
      updatedClassDays = updatedClassDays.filter(
        (day) => day !== value
      ); // Remove the day if unchecked
    }

    // Update the state with the new classDaysOfWeek list
    setValues({
      ...values,
      classDaysOfWeek: updatedClassDays,
    });
  };

  useEffect(() => {
    const fetchAllTeachers = async () => {
      try {
        const response2 = await SchoolServiceAdmin.getAllTeachers();
        setAllTeachers(response2.data);
        setValues((values) => ({
          ...values,
          teacherId: response2.data[0]?.id
            ? response2.data[0]?.id
            : -1,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllTeachers();
  }, []);
  const [allTeachers, setAllTeachers] = useState(null);

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setValues((values) => ({
      ...values,
      teacherId: event.target.value,
    }));
  };

  return (
    <div className="flex border flex-col items-center justify-center px-6 py-8 ">
      <h1 className="flex items-center mb-6 text-2xl font-semibold text-gray-900 ">
        SIGN UP STUDENT
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="firstName"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              First Name
            </label>
          </div>
        </div>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="lastName"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Last Name
            </label>
          </div>
        </div>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="firstNameArabic"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              First Name Arabic
            </label>
          </div>
        </div>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="lastNameArabic"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Last Name Arabic
            </label>
          </div>
        </div>
        <div className="flex w-72 flex-col gap-6 my-4">
          <div className="relative h-10 w-full min-w-[200px]">
            <input
              name="email"
              onChange={handleChange}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-purple-700 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Email
            </label>
          </div>
        </div>

        <div className="mb-4 flex-col items-center mx-auto space-y-4">
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className="w-full appearance-none bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            {allTeachers?.map((t) => (
              <option key={t.id} value={t.id}>
                {t.id + ' - ' + t.firstName + ' - ' + t.lastName}
              </option>
            ))}
          </select>
        </div>

        <div className="flex w-72 text-center flex-col gap-6 my-4">
          <div className="relative h-auto w-full">
            <h3 className="text-center mb-6 text-2xl font-semibold text-gray-900 ">
              Class Days Of Week
            </h3>

            <ul className="mx-auto w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="vue-checkbox"
                    type="checkbox"
                    value="Sat"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Sat')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="vue-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Sat
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="react-checkbox"
                    type="checkbox"
                    value="Sun"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Sun')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="react-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Sun
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="angular-checkbox"
                    type="checkbox"
                    value="Mon"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Mon')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="angular-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Mon
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="laravel-checkbox"
                    type="checkbox"
                    value="Tue"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Tue')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="laravel-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Tue
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="laravel-checkbox"
                    type="checkbox"
                    value="Wed"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Wed')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="laravel-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Wed
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="laravel-checkbox"
                    type="checkbox"
                    value="Thu"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Thu')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="laravel-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Thu
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                <div className="flex items-center ps-3">
                  <input
                    id="laravel-checkbox"
                    type="checkbox"
                    value="Fri"
                    onChange={handleCheckboxChange}
                    checked={values.classDaysOfWeek.includes('Fri')}
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="laravel-checkbox"
                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Fri
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex w-72 flex-col gap-6">
          <button
            type="submit"
            onClick={handleSubmit}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-900"
          >
            Sign Up
          </button>
          <button
            onClick={() => {
              navigate('/schoolsuperadmin');
            }}
            className="rounded bg-cyan-700 hover:bg-cyan-800  text-white px-6 py-2 font-semibold "
          >
            Back To Dashboard
          </button>
        </div>
      </form>

      {signupFailure.length > 0 && (
        <div className="rounded bg-red-600 text-white my-5 py-4 w-96 text-center">
          {signupFailure}
        </div>
      )}
    </div>
  );
};

export default CreateStudent;
