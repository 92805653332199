import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import annourLogo from '../imgs/annour-logo.png';
import { useMediaQuery } from 'react-responsive';

import SchoolServiceAdmin from '../services/SchoolServiceAdmin';
import AttendanceTable from './AfterLoginPages/AttendanceTable';
import StatisticsAttendanceData from './AfterLoginPages/StatisticsAttendanceData';
import { levelColors, levelTranslations } from './ConstFuncs';

const StudentGroup = ({ data, setData }) => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  const [myStudentsOnDateRange, setMyStudentsOnDateRange] =
    useState(null);
  let { teacherId } = useParams();

  useEffect(() => {
    if (!data) {
      localStorage.clear();
      navigate('/');
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response =
        await SchoolServiceAdmin.getAdminAllStudentsForTeacher(
          teacherId
        );
      setAllStudents(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'start') {
      setStartDate(value);
    } else if (name === 'end') {
      setEndDate(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log('Start Date:', startDate);
    //console.log('End Date:', endDate);
    try {
      const response =
        await SchoolServiceAdmin.getMyStudentsOnDateRange(
          startDateRef.current.value,
          endDateRef.current.value,
          teacherId
        );
      setMyStudentsOnDateRange(
        response.data.length <= 0 ? null : response.data
      );
      //console.log('RES: ', response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto my-8">
      {!loading && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white text-sm md:text-base lg:text-lg">
              <tr>
                <th className="py-2">First Name Arabic</th>
                <th className="py-2">Last Name Arabic</th>
                <th className="py-2">Parents Email</th>
                <th className="py-2">Class Days Of Week</th>
                <th className="py-2">Student Level</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2">{myStudent.lastNameArabic}</td>
                  <td className="py-2">{myStudent.email}</td>
                  <td className="py-2">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td
                    className={`py-2 font-semibold text-gray-300 ${
                      myStudent.level != null
                        ? levelColors[myStudent.level]
                        : 'bg-slate-600'
                    }`}
                  >
                    {myStudent.level != null
                      ? levelTranslations[myStudent.level]
                      : 'لم يتم الاختيار بعد'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 py-4">
        Get Absence List For DateRange:
      </h1>
      <div className="w-full flex justify-center">
        <form onSubmit={handleSubmit} className="text-center">
          <input
            ref={startDateRef}
            type="date"
            name="start"
            value={startDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
          <input
            ref={endDateRef}
            type="date"
            name="end"
            value={endDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
          />
          <button
            type="submit"
            className="rounded text-white px-8 py-3 my-2 font-semibold  bg-cyan-700 hover:bg-cyan-800"
          >
            Submit
          </button>
        </form>
      </div>

      {isTabletOrMobile
        ? myStudentsOnDateRange && (
            <div>mobile view not ready yet</div>
          )
        : myStudentsOnDateRange && (
            <>
              <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
                <AttendanceTable data={myStudentsOnDateRange} />
              </div>
              <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
                P:Present | H:Homework Done | L:Late | D:Disturb
              </h1>
              <StatisticsAttendanceData
                data={myStudentsOnDateRange}
              />
            </>
          )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <div className="mx-auto text-center">
        <button
          onClick={() => {
            navigate('/schooladmin');
          }}
          className="rounded text-white px-6 py-2 font-semibold  bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default StudentGroup;
