import React from 'react';

const PurposeOfAssociation = () => {
  return (
    <div className="flex-col justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl mx-auto mt-10">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Zweck des Vereins
        </h1>

        <div>
          <p>
            <span className="text-2xl font-bold">1.</span> Der Verein
            „Arabische Schule in Braunschweig Annour e.V.“ mit Sitz in
            Braunschweig verfolgt ausschließlich und unmittelbar
            gemeinnützige Zwecke im Sinne des Abschnitts
            „Steuerbegünstigte Zwecke“ der Abgabenordnung. Der Verein
            ist selbstlos tätig, er verfolgt nicht in erster Linie
            eigenwirtschaftliche Zwecke.
          </p>
          <br />
          <p>Die Zwecke des Vereins sind:</p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">a)</span> Die
            Förderung der Erziehung, Volks- und Berufsbildung
            einschließlich der Schuler und Studentenhilfe.
          </p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">b)</span> Förderung
            des Sports
          </p>
          <br />
        </div>
        <div>
          <p>
            <span className="text-2xl font-bold">2.</span> Die
            Satzungszwecke werden verwirklicht insbesondere durch:
          </p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">a)</span> Unterricht
            der arabischen Sprache für Kinder und Jugendliche aus
            arabischsprachigen Elternhäusern, aber auch
            deutsch-sprachige Kinder und Jugendliche mit Interesse an
            der arabischen Sprache und Kultur sind gern gesehen.
          </p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">b)</span>{' '}
            Unterrichts-Nachhilfe und Unterstützung für Kinder und
            Jugendliche aus arabischsprachigen Elternhäusern, aber
            auch für andere Schüler zur Verbesserung ihrer Zensuren in
            den deutschen Schulen.
          </p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">c)</span> Eine
            besondere Zielsetzung des Vereins besteht darin, dass der
            Verein darauf hinwirkt, eine Anerkennung und Bestätigung
            des Arabisch-Unterrichts in den Zeugnissen der staatlichen
            oder staatlich geförderten Schulen zu erhalten.
          </p>
          <br />
          <p className="pl-8">
            <span className="text-lg font-bold">d)</span>{' '}
            Sportsaktivitäten Die Umsetzung dieser Ziele geschieht
            hauptsächlich durch Sprach-unterricht in Hocharabisch für
            Schülerinnen und Schüler sowie durch die Einführung in
            klassischer arabischer Literatur, Dichtung und Kultur. Zu
            diesem Zwecke wird die Errichtung einer Bibliothek zur
            arabischen Literatur angestrebt.
          </p>
          <br />
        </div>
        <div>
          <p>
            <span className="text-2xl font-bold">3.</span> Zur
            Verwirklichung dieser Ziele arbeitet Annour e.V. mit
            anderen Vereinen und Organisationen zusammen, die ähnliche
            Zielsetzungen verfolgen.
          </p>
          <br />
        </div>
        <div>
          <p>
            <span className="text-2xl font-bold">4.</span> Der Verein
            soll zunächst im Bereich Braunschweig und Umgebung wirken.
            Er kann auch Zweigniederlassungen eröffnen.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};
export default PurposeOfAssociation;
