import axios from 'axios';
import { request } from '../api/authenticationService';

class SchoolService {
  getMyStudents() {
    return request('GET', '/students');
  }
  getMyStudentsOnDate(date) {
    return request('GET', `/myStudentsOnDate?date=${date}`);
  }
  updateMyStudentsOnDate(studentDate) {
    return request('PUT', `/updateMyStudentsOnDate`, studentDate);
  }

  updateLevelToStudent(teacherId, studentId, studentNewLevel) {
    return request(
      'PUT',
      `/updateLevelToStudent/${teacherId}?studentId=${studentId}&level=${studentNewLevel}`
    );
  }
}

export default new SchoolService();
