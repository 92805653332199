import React from 'react';
import annourLogo from '../imgs/annour-logo.png';

const StudentOnDate = ({
  myStudentsOnDate,
  index,
  changePresenceStatus,
  changeLateStatus,
  changeHomeworkDoneStatus,
  changeDisturbStatus,
}) => {
  return (
    <div className="w-full text-left sm:w-full md:w-full lg:w-1/2 xl:w-1/3">
      <button
        onClick={() => changePresenceStatus(index)}
        className={` rounded-ss-full rounded-ee-full bg-white -mb-7	border shadow-xl p-5 mt-10 mx-5 md:mx-0
      flex items-center w-11/12
    ${
      myStudentsOnDate.present ? 'shadow-green-700' : 'shadow-red-700'
    }
    transition ease-in-out cursor-pointer lg:hover:-translate-y-1 lg:hover:scale-110 duration-300 relative z-20`}
      >
        <img
          className={`h-auto max-w-xl shadow-xl 
          ${
            myStudentsOnDate.present
              ? 'shadow-green-700'
              : 'shadow-red-700'
          }
           w-16 h-16 rounded-full `}
          src={annourLogo}
          alt="img"
        />
        <div
          className={
            'md:pl-10 pl-3 text-cyan-800 font-semibold text-2xl'
          }
        >
          <p>{myStudentsOnDate.studentEntity.firstNameArabic}</p>
          <p>{myStudentsOnDate.studentEntity.lastNameArabic}</p>
        </div>
      </button>
      <button
        className={`border flex rounded-br-xl rounded-bl-xl bg-gray-300 text-black px-4 py-9 -mb-7 z-10 relative shadow-xl p-5 mt-5 mx-5
        ${
          myStudentsOnDate.homeworkDone
            ? 'bg-green-400'
            : 'bg-red-200'
        }
         text-2xl`}
        onClick={() => {
          myStudentsOnDate.present && changeHomeworkDoneStatus(index);
        }}
      >
        {'عمل الواجب المدرسي'}
      </button>
      <button
        className={`border rounded-br-xl rounded-bl-xl mr-1 ml-7 text-black px-4 py-9 
        ${myStudentsOnDate.disturb ? 'bg-green-400' : 'bg-red-200'}
         text-2xl`}
        onClick={() => {
          myStudentsOnDate.present && changeDisturbStatus(index);
        }}
      >
        إزعاج
      </button>
      <button
        className={`border rounded-br-xl rounded-bl-xl mr-1 ml-1 text-black px-4 py-9 
        ${myStudentsOnDate.late ? 'bg-green-400' : 'bg-red-200'}
         text-2xl`}
        onClick={() => {
          myStudentsOnDate.present && changeLateStatus(index);
        }}
      >
        تأخير
      </button>
    </div>
  );
};

export default StudentOnDate;
