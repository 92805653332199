import { useState } from 'react';
import SchoolService from '../services/SchoolService';

const StudentLevelPopup = ({
  isOpen,
  togglePopup,
  level,
  student,
  teacherId,
}) => {
  const [newLevel, setNewLevel] = useState(
    level !== null ? level : 'Hervorragend'
  );

  const submit = () => {
    console.log(student.id, teacherId, newLevel);

    SchoolService.updateLevelToStudent(
      teacherId,
      student.id,
      newLevel
    );

    student.level = newLevel;

    togglePopup();
  };
  const handleChange = (event) => {
    //console.log(student.id, teacherId, event.target.value);
    setNewLevel(event.target.value);
    //console.log(data);
  };

  return !isOpen ? (
    <></>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-100">
        <div className="bg-white p-6 rounded shadow-lg transform transition-all duration-300">
          <h2 className="text-2xl mb-4">
            :اختر المستوى من أجل الطالب/ة
          </h2>
          <h2 className="text-2xl mb-4">
            {student.firstNameArabic + ' ' + student.lastNameArabic}
          </h2>
          <select
            value={newLevel}
            className="py-1 px-2 dropdown font-bold text-xl mb-10"
            onChange={handleChange}
          >
            <option value="Hervorragend">ممتاز</option>
            <option value="Gut">جيد</option>
            <option value="Ausreichend">عادي</option>
            <option value="Schwach">ضعيف</option>
          </select>
          <br />
          <button
            onClick={togglePopup}
            className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-5 rounded mx-4"
          >
            إلغاء
          </button>
          <button
            onClick={submit}
            className="mt-4 bg-green-700 hover:bg-green-800 text-white font-bold py-3 px-5 rounded mx-4"
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentLevelPopup;
