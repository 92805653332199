export const levelTranslations = {
  Hervorragend: 'ممتاز',
  Gut: 'جيد',
  Ausreichend: 'عادي',
  Schwach: 'ضعيف',
  // Add other levels and their translations as needed
};
export const levelColors = {
  Hervorragend: 'bg-green-700',
  Gut: 'bg-green-600',
  Ausreichend: 'bg-orange-500',
  Schwach: 'bg-red-700',
  // Add other levels and their translations as needed
};
