import React from 'react';
import { useNavigate } from 'react-router-dom';
import homeBg from '../../imgs/schule4.jpeg';
import AboutMembers from './AboutMembers';

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex bg-bg items-center justify-center py-5 text-black mx-auto home-page-center-element relative  z-10">
      <AboutMembers />
    </div>
  );
};

export default AboutPage;
