import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import annourLogo from '../imgs/annour-logo.png';

import SchoolServiceAdmin from '../services/SchoolServiceAdmin';

const UpdateStudentGroup = ({ data, setData }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  let { teacherId } = useParams();

  useEffect(() => {
    if (!data) {
      localStorage.clear();
      navigate('/');
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response =
        await SchoolServiceAdmin.getAllStudentsForTeacher(teacherId);
      setAllStudents(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="container mx-auto my-8">
      {!loading && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">First Name Arabic</th>
                <th className="py-2 px-4">Last Name Arabic</th>
                <th className="py-2 px-4">Parents Email</th>
                <th className="py-2 px-4">Class Days Of Week</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2 px-4 ">{myStudent.id}</td>
                  <td className="py-2 px-4">{myStudent.firstName}</td>
                  <td className="py-2 px-4">{myStudent.lastName}</td>
                  <td className="py-2 px-4">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.lastNameArabic}
                  </td>
                  <td className="py-2 px-4">{myStudent.email}</td>
                  <td className="py-2 px-4">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td className="py-2 px-4 ">
                    <button
                      className="rounded  bg-red-700 hover:bg-red-800 text-white px-6 py-2 font-semibold "
                      onClick={async () => {
                        await SchoolServiceAdmin.deleteStudent(
                          teacherId,
                          myStudent.id
                        );
                        fetchData();
                      }}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <div className="mx-auto text-center">
        <button
          onClick={() => {
            navigate('/schoolsuperadmin');
          }}
          className="rounded text-white px-6 py-2 font-semibold  bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default UpdateStudentGroup;
