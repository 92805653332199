import { request } from '../api/authenticationService';

class SchoolServiceAdmin {
  getAllStudents() {
    return request('GET', '/admin/allstudents');
  }
  getAllTeachers() {
    return request('GET', '/admin/allteachers');
  }
  getAllArchivedStudents() {
    return request('GET', '/admin/allarchivedstudents');
  }
  getAllHolidays() {
    return request('GET', '/admin/allholidays');
  }

  getMyStudentsOnDateRange(startDate, endDate, teacherId) {
    return request(
      'GET',
      `/admin/myStudentsOnDateRange/${teacherId}?startdate=${startDate}&enddate=${endDate}`
    );
  }

  getMyStudentsOnDate(date) {
    return request('GET', `/myStudentsOnDate?date=${date}`);
  }
  updateMyStudentsOnDate(studentDate) {
    return request('PUT', `/updateMyStudentsOnDate`, studentDate);
  }

  getAllStudentsForTeacher(teacherId) {
    return request(
      'GET',
      `/superadmin/updatestudentgroup/${teacherId}`
    );
  }
  getAdminAllStudentsForTeacher(teacherId) {
    return request('GET', `/admin/getstudentgroup/${teacherId}`);
  }
  deleteStudent(teacherId, studentId) {
    return request(
      'DELETE',
      `/superadmin/deletestudentfromteacher/${teacherId}?studentId=${studentId}`
    );
  }
  deleteTeacher(teacherId) {
    return request(
      'DELETE',
      `/superadmin/deleteteacher/${teacherId}`
    );
  }

  deleteHoliday(id) {
    return request('DELETE', `/superadmin/deleteholiday/${id}`);
  }

  createNewHoliday(date) {
    return request(
      'POST',
      `/superadmin/createholidaydate?date=${date}`
    );
  }
  updateSendEmailFlag(isChecked) {
    return request(
      'POST',
      `/superadmin/updateSendEmailFlag?sendEmail=${isChecked}`
    );
  }

  createNewAbsenceListForTeacherOnDate(teacherId, date) {
    return request(
      'POST',
      `/superadmin/createAbsenceListForTeacher/${teacherId}?date=${date}`
    );
  }
  deleteAbsenceListForTeacherOnDate(teacherId, date) {
    return request(
      'DELETE',
      `/superadmin/deleteAbsenceListForTeacher/${teacherId}?date=${date}`
    );
  }
}

export default new SchoolServiceAdmin();
