import React from 'react';

const AttendanceTable = ({ data }) => {
  // Extract all dates from the dataset to form the header row
  const allDates = [
    ...new Set(
      data.flatMap((student) =>
        student.map((entry) => entry.dateEntity.date)
      )
    ),
  ].sort();

  return (
    <table className="min-w-full border rounded overflow-hidden">
      <thead className="bg-gray-800 text-white text-sm md:text-base lg:text-lg">
        <tr>
          <th className="py-2">Name Arabic</th>
          {allDates.map((date) => (
            <th key={date} className="py-2">
              {date}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((studentRecords, index) => {
          if (
            studentRecords === undefined ||
            studentRecords === null ||
            studentRecords?.length <= 0
          )
            return <></>;
          const studentName = `${studentRecords[0].studentEntity.firstNameArabic} , ${studentRecords[0].studentEntity.lastNameArabic}`;
          // Create a mapping of dates to records for the current student
          const recordsByDate = studentRecords.reduce(
            (acc, record) => {
              acc[record.dateEntity.date] = record;
              return acc;
            },
            {}
          );

          return (
            <tr className="border-b text-center" key={index}>
              <td className="py-2">{studentName}</td>
              {allDates.map((date) => {
                const record = recordsByDate[date];
                return (
                  <td key={date} className="py-2">
                    <span
                      className={`${
                        record?.present
                          ? 'text-green-700 font-bold'
                          : ''
                      } pr-7`}
                    >
                      {record?.present ? 'P' : '-'}
                    </span>
                    {record?.homeworkDone ? 'H' : '-'}
                    <span
                      className={`${
                        record?.late ? 'text-red-700 font-bold' : ''
                      } pl-7`}
                    >
                      {record?.late ? 'L' : '-'}
                    </span>
                    <span
                      className={`${
                        record?.disturb
                          ? 'text-red-700 font-bold'
                          : ''
                      } pl-7`}
                    >
                      {record?.disturb ? 'D' : '-'}
                    </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AttendanceTable;
